import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SVG from "react-inlinesvg"
import {
  BlogHeader,
  DirectRoute,
  ObjectsPortal,
  ProblemSolver,
  HorizontalLinePinBlue,
} from "../components/svg"
import { Seo, Layout, Section, Grid, Container, Col } from "../components"
import { useSanity } from "../hooks"

function Blog() {
  const { posts, featured } = useSanity()

  return (
    <Layout>
      <Seo
        title="Check Our Blog for News & Technology Updates"
        description="Stay up to date in the ever-growing, ever-changing shipping landscape of retailers, e-commerce companies and 3PLs."
        slug="blog"
      />
      <Section padding="none">
        <div className="bg-gradient-radial from-[#3C4C5C] to-[#21252E]">
          <div className="global-container-md">
            <h1 className="font-medium text-2xl text-center text-white mt-1 mb-0">
              ShipX Blog
            </h1>
            <div className="h-auto">
              <BlogHeader />
            </div>
          </div>
        </div>

        <Section padding="none">
          <Container padding="global-md" classes="space-y-12">
            {/* <Grid classes="gap-6 justify-center bg-secondary p-8">
              <h2 className="text-center mb-2">Featured Post</h2>
              {featured.map(featuredPosts =>
                featuredPosts.featuredPosts.map(feature => (
                  <Col classes="bg-white p-6">
                    <Link
                      to={feature.slug.current}
                      className="space-y-6 prose-h2:hover:text-accent transition"
                    >
                      <Container padding="none" classes="overflow-hidden">
                        <GatsbyImage
                          image={feature?.image?.asset?.gatsbyImageData}
                          className="aspect-w-12 aspect-h-7 w-full h-full object-cover transition hover:scale-105"
                          alt={feature?.image?.alt}
                          loading="lazy"
                        />
                      </Container>
                      <h2 className="text-left text-xl text-secondary-dark">
                        {feature?.title}
                      </h2>
                      <div className="flex justify-start items-center gap-2 text-secondary-dark text-xs sm:text-sm">
                        <GatsbyImage
                          image={feature?.author.image?.asset?.gatsbyImageData}
                          className="w-8 h-8 object-cover rounded-full shadow-xl transition hover:scale-105"
                          alt={feature?.image?.alt}
                          loading="lazy"
                        />
                        <p>by {feature?.author.name}</p>
                        <p className="text-accent font-bold">·</p>
                        <p>{feature?.date}</p>
                      </div>
                    </Link>
                  </Col>
                ))
              )}
            </Grid> */}
            <Grid classes="lg:grid-cols-1 gap-6 justify-center">
              {posts.map(post => (
                <Col classes="bg-white m-auto p-6 shadow-xl">
                  <Link
                    to={`/blog/${post.slug.current}/`}
                    className="space-y-6 prose-h2:hover:text-accent transition"
                  >
                    <Grid classes="lg:grid-cols-12 gap-6">
                      <Col
                        padding="none"
                        classes="overflow-hidden lg:col-span-5"
                      >
                        <GatsbyImage
                          image={post?.imageThumbnail?.asset?.gatsbyImageData}
                          className="aspect-w-12 aspect-h-7 w-full h-full object-cover transition hover:scale-105"
                          alt={post?.imageThumbnail?.alt}
                          loading="lazy"
                        />
                      </Col>
                      <Col classes="lg:col-span-7">
                        <div className="space-y-7">
                          <h2 className="text-left text-xl text-secondary-dark">
                            {post?.title}
                          </h2>
                          <p>{post?.excerpt}</p>
                          <p className="text-gray-400 text-sm">{post?.date}</p>
                        </div>
                        {/* <div className="flex justify-start items-center gap-2 text-secondary-dark text-xs sm:text-sm">
                          <GatsbyImage
                            image={post?.author.image?.asset?.gatsbyImageData}
                            className="w-8 h-8 object-cover rounded-full shadow-xl transition hover:scale-105"
                            alt={post?.image?.alt}
                            loading="lazy"
                          />
                          <p>by {post?.author.name}</p>
                          <p className="text-accent font-bold">·</p>
                          <p className="text-gray-400 text-sm">{post?.date}</p>
                        </div> */}
                      </Col>
                    </Grid>
                  </Link>
                </Col>
              ))}
            </Grid>
          </Container>
        </Section>
        <Section padding="none">
          <Container
            padding="none"
            bgColor="bg-accent-variation"
            classes="pt-24 pb-24 lg:pt-24 lg:pb-0 lg:px-0 text-center space-y-24"
          >
            <p className="text-secondary-dark font-bold max-w-xl mx-auto text-2xl lg:text-3xl px-4">
              Let ShipX create a custom e-commerce delivery solution for you.
            </p>
            <div>
              <Link
                to="/contact/"
                className="px-10 py-6 text-white font-bold lg:text-3xl sm:text-2xl rounded-full bg-secondary hover:bg-accent hover:text-white transition duration-150 ease-in-out"
              >
                Schedule a meeting
              </Link>
            </div>
            <div className="pl-6 flex justify-end">
              <HorizontalLinePinBlue className="w-full lg:w-1/3 relative lg:bottom-[150px] xl:bottom-[150px] 2xl:bottom-[150px]" />
            </div>
          </Container>
        </Section>
      </Section>
    </Layout>
  )
}

export default Blog
